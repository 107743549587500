import React from 'react';
import '../global-styles.css';

export default function PrivacyPolicy() {
  return (
    <div>
      <h1 className={'title'} >Políticas de Privacidade</h1>

      <h4><strong>SEÇÃO 1 - O QUE FAREMOS COM SEUS DADOS?</strong></h4>
      <p><span className={'body-text'}>Quando você; cria sua conta utilizando redes sociais como o Facebook ou o
        Google, utilizamos seu nome e e-mail para criar sua conta no nosso banco de dados. Nós não
        utilizamos
        esses dados para outro fim a não ser esse. Caso você; opte por criar a conta usando e-mail e senha,
        iremos utilizar apenas o e-mail para te identificar no banco de dados e te mostrar os seus dados que você;
        criou com o Granna.&nbsp;</span></p>
      <p><span className={'body-text'}>Quando você; cria as metas e transações dentro do aplicativo,
        nós utilizamos apenas os nomes e os valores de cada meta para criar categorias e ícones de
        customização que mais se adequa a maioria das metas que os usuários criam.</span></p>
      <h4><strong>SEÇÃO 2 - CONSENTIMENTO</strong></h4>
      <p><span className={'body-text'}>Como você;s obtê;m meu consentimento?</span></p>
      <p><span className={'body-text'}>Quando você; fornece informações pessoais como nome e e-mail,
        para
        criar sua conta. A partir disso, entendemos que você; está de acordo com a coleta de dados para serem
        utilizados pela nossa empresa.</span></p>
      <p><span className={'body-text'}>E caso você; queira retirar seu consentimento, como proceder?</span></p>
      <p><span className={'body-text'}>Se após você; nos fornecer seus dados, você; mudar de ideia,
        você; poderá excluir a sua conta a qualquer momento. Fazendo isso, todos os seus dados serão
        apagados do nosso banco de dados e não poderão ser recuperados. Para excluir os seus dados, basta acessar a área "Configurações" e "Excluir Conta". </span></p>
      <h4><strong>SEÇÃO 3 - DIVULGAÇÃO</strong></h4>
      <p><span className={'body-text'}>Podemos divulgar seus dados pessoais como e-mail e nome caso sejamos obrigados pela
        lei para fazê;-lo ou se você; violar nossos Termos de Serviço.</span></p>
      <h4><strong>SEÇÃO 4 - SERVIÇOS DE TERCEIROS</strong></h4>
      <p><span className={'body-text'}>No geral, os fornecedores terceirizados usados por nós irão coletar
        apenas informações básicas do seu dispositivo para gerar relatórios de erros. Nenhum
        de
        seus dados pessoais serão divulgados.</span></p>
      <p><span className={'body-text'}>Uma vez que você; deixe nosso aplicativo ou seja redirecionado para um
        aplicativo ou site de terceiros, você; não será mais regido por essa Política de
        Privacidade ou pelos Termos de Serviço do nosso aplicativo.</span></p>
      <p><span className={'body-text'}>Links</span></p>
      <p><span className={'body-text'}>Quando você; clica em links no nosso aplicativo, eles podem lhe direcionar
        para
        fora do nosso aplicativo. Não somos responsáveis pelas práticas de privacidade de outros
        sites
        e lhe incentivamos a ler as declarações de privacidade deles.</span></p>
      <p><span className={'body-text'}>Permissões do Facebook solicitadas por este aplicativo</span></p>
      <p><span className={'body-text'}>Caso opte por criar uma conta ou realizar login utilizando o Facebook, o aplicativo
        poderá&nbsp; pedir algumas permissões ao Facebook que possibilitam te autenticar no nosso aplicativo
        utilizando a autenticação do Facebook. Este aplicativo poderá se conecte com a conta do
        usuário na rede social Facebook.</span></p>
      <p><span className={'body-text'}>Para mais informações sobre as seguintes permissões, consulte
        a
        documentação de permissões Facebook e a política de privacidade do Facebook.</span>
      </p>
      <p><span className={'body-text'}>As permissões solicitadas são as seguintes: E-mail e
        Informações básicas.</span></p>
      <h4><strong>SEÇÃO 5 - SEGURANÇA</strong></h4>
      <p><span className={'body-text'}>Para proteger suas informações pessoais, tomamos
        precauções razoáveis e seguimos as melhores práticas da indústria para nos
        certificar que elas não serão perdidas inadequadamente, usurpadas, acessadas, divulgadas, alteradas
        ou
        destruídas.</span></p>
      <p><span className={'body-text'}>Embora nenhum método de transmissão pela Internet ou armazenamento
        eletrônico é 100% seguro, nós seguimos todos os requisitos e implementamos padrões
        adicionais geralmente aceitos pela indústria.</span></p>
      <h4><strong>SEÇÃO 6 - ALTERAÇÕES PARA ESSA POLíTICA DE PRIVACIDADE</strong></h4>
      <p><span className={'body-text'}>Reservamos o direito de modificar essa política de privacidade a qualquer
        momento, então por favor, revise-a com frequê;ncia. Alterações e esclarecimentos
        vão surtir efeito imediatamente após sua publicação no aplicativo.</span></p>
    </div>
  );
}
import React from 'react';
import '../global-styles.css';

export default function TermsAndPolicy() {
  return (
    <div>
      <h1 className={'title'}>Termos de Serviço e Políticas de Privacidade</h1>

      <p className={'link'}>
        <strong>
          <a href="/terms-of-service">Termos de Serviço</a>
        </strong>
      </p>

      <p className={'link'}>
        <strong>
          <a href="/privacy-policy">Políticas de Privacidade</a>
        </strong>
      </p>

    </div>
  );
}
import React from 'react';

export default function Home({ history }) {

  function download() {
    history.push('/download')
  }

  return (
    <>
      <h1>Granna</h1>
      <button onClick={download}>Download</button>
    </>
  );
}
